import ApiService from '../Api';

class PackageServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/packages`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/packages`, body);
  }

  update(archon, body, pack) {
    return this.api.put(`${this.resource}/${archon}/packages/${pack}`, body);
  }

  destroy(archon, pack) {
    return this.api.delete(`${this.resource}/${archon}/packages/${pack}`);
  }
}

export default new PackageServices();