<template>
  <v-dialog v-model="dialog" width="40%">
    <v-card>
      <Loader :loading="loading" absolute />
      <v-card-title>
        <v-icon left>mdi-package-variant</v-icon>
        {{ packageId === -1 ? 'Nuevo Paquete' : 'Editar Paquete' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" onSubmit="return false;">
          <v-text-field
            class="package--uppercase"
            v-model="form.name"
            label="Nombre"
            :rules="[isRequired]"
            :error-messages="errorName"
          />
          <v-text-field
            v-model="form.description"
            label="Descripción"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="cancel"
          small
          color="grey lighten-1"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="packageId === -1"
          @click="storePackage"
          small
          color="primary"
        >
          Guardar
        </v-btn>
        <v-btn
          v-else
          @click="updatePackage"
          small
          color="warning"
        >
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PackageServices from '@/store/services/warehouse/PackageServices';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        name: '',
        description: '',
      },
      packageId: -1,

      // rules
      isRequired: value => !!value || 'Este campo es requerido',

      errorName: '',
    };
  },
  methods: {
    cancel() {
      this.resetData();
    },
    resetData() {
      this.form = {
        name: '',
        description: '',
      };
      this.$refs.form.resetValidation();
      this.dialog = false;

      this.packageId = -1;
    },
    async storePackage() {
      if (this.$refs.form.validate()) {
        try {
          this.errorName = '';
          this.loading = true;
          const response = await PackageServices.store(this.user.archon.id, {
            name: this.form.name.toUpperCase(),
            description: this.form.description,
          });
          this.$emit('onSendPackage', {
            package: response.data,
            type: 'store',
          });
          this.resetData();
          this.loading = false;
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            const errorName = error.response.data.errors?.name[0] || null;
            if (errorName) {
              this.errorName = 'Nombre de paquete ya esta en uso';
            }
          }
          console.log(error);
        }
      }
    },
    async updatePackage() {
      if (this.$refs.form.validate()) {
        try {
          this.errorName = '';
          this.loading = true;
          await PackageServices.update(this.user.archon.id, {
            name: this.form.name.toUpperCase(),
            description: this.form.description,
          }, this.packageId);
          this.$emit('onSendPackage', {
            package: Object.assign({}, this.form),
            packageId: this.packageId,
            type: 'update',
          });
          this.resetData();
          this.loading = false;
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            const errorName = error.response.data.errors?.name[0] || null;
            if (errorName) {
              this.errorName = 'Nombre de paquete ya esta en uso';
            }
          }
          console.log(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
};
</script>

<style scoped>
.package--uppercase >>> input {
  text-transform: uppercase;
}
</style>