<template>
  <v-card flat>
    <Loader :loading="loading" />
    <div class="d-flex justify-end">
      <v-btn
        color="warning"
        small
        text
        @click="getPackages"
      >
        <v-icon left>mdi-refresh</v-icon>
        Refrescar
      </v-btn>
      <v-btn
        color="primary"
        small
        text
        @click="createPackage"
      >
        <v-icon left>mdi-plus</v-icon>
        Paquete
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="packages"
      class="elevation-0"
      item-key="id"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.actions="{ item }">
        <div v-if="!packagesDefault.includes(item.name)">
          <v-progress-circular
            v-if="packageId === item.id"
            indeterminate
            color="primary"
          />
          <v-menu
            v-else
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                link
                @click="removePackage(item)"
              >
                <v-icon left>mdi-trash-can</v-icon>
                <small>Eliminar</small>
              </v-list-item>
            </v-list>
            <v-list dense>
              <v-list-item
                link
                @click="editPackage(item)"
              >
                <v-icon left>mdi-pencil</v-icon>
                <small>Editar</small>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <PackageCreate
      ref="packageCreate"
      @onSendPackage="onGetPackage"
    />
  </v-card>
</template>

<script>
// components
import PackageCreate from './PackageCreate';
// services
import PackageServices from '@/store/services/warehouse/PackageServices';
// helpers
import { mapGetters } from 'vuex';
export default {
  components: {
    PackageCreate,
  },
  mounted() {
    this.getPackages();
  },
  data() {
    return {
      loading: false,
      packageId: -1,
      packages: [],

      packagesDefault: [
        'CAJA',
        'DOCENA',
        'PIEZA',
      ],
    };
  },
  methods: {
    createPackage() {
      this.$refs['packageCreate'].dialog = true;
    },
    onGetPackage(data) {
      if (data.type === 'store') this.packages.unshift(data.package);
      else {
        const index = this.packages.findIndex(pack => pack.id === data.packageId);
        this.packages[index].name = data.package.name;
        this.packages[index].description = data.package.description;
      }
    },
    async getPackages() {
      try {
        this.loading = true;
        const response = await PackageServices.index(this.user.archon.id);
        this.packages = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async removePackage(item) {
      try {
        this.packageId = item.id;
        await PackageServices.destroy(this.user.archon.id, item.id);
        const index = this.packages.findIndex(pack => pack.id === item.id);
        console.log(index);
        this.packages.splice(index, 1);
        this.packageId = -1;
      } catch (error) {
        this.packageId = -1;
      }
    },
    editPackage(item) {
      this.$refs['packageCreate'].dialog = true;
      this.$refs['packageCreate'].packageId = item.id;
      this.$refs['packageCreate'].form = {
        name: item.name,
        description: item.description,
      };
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Paquete',
          sortable: false,
          value: 'name',
        },
        { text: 'Descripción', value: 'description', sortable: false },
      ];
    },
  },
}
</script>